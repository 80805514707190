import React from "react"
import { Link } from "gatsby"
import { useTransition, a } from "react-spring";
import "./slides.scss"

import Active from "../images/MTV_active.svg";
import Calm from "../images/MTV_calm.svg";
import Connected from "../images/MTV_connected.svg";
import Home from "../images/MTV_home.svg";
import Resources from "../images/MTV_resources.svg";
import AloneTogetherLogo from "../images/alone_together_logo.svg"
import Snowflake from "../images/MTV_snowflake.svg";

const icons = {
  active: <Active />,
  calm: <Calm />,
  connected: <Connected />,
  home: <Home />,
  resources: <Resources />,
  snowflake: <Snowflake />
}

const Slide = ({
  heading,
  copy,
  linkCopy,
  link,
  icon,
  cta,
  index,
  current
}) => {

  const t = useTransition(current, null, {
    from:  {
      opacity: 0,
      transform: `scale(1.2)`
    },
    enter: {
      opacity: 1,
      transform: `scale(1)`
    },
    leave: {
      opacity: 0,
      transform: `scale(1.2)`
    }
  });

  return <React.Fragment>
    <div className={`Slide-wrap`}>
      {index === 0 ?
        <AloneTogetherLogo />
        : null
      }
      <h2>{heading}</h2>
      <p className="Slide-subhead" dangerouslySetInnerHTML={{ __html: copy }} />
      {!cta ?
        <Link to={link}>{linkCopy}</Link>
        : <div className="Slide-copy" dangerouslySetInnerHTML={{ __html: cta }} />
      }
    </div>
    {t.map(({item, key, props}) => (item && <a.div
      key={key}
      className="Slide-svg"
      style={props}>
        {icons[icon]}
      </a.div>
    ))}
  </React.Fragment>
}
export default Slide
