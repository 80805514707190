import React, { useState, useEffect } from "react";
import { useSpring, a } from "react-spring";
import Layout from "../components/layout";
import Slide from "../components/slide";
import SlideNav from "../components/slide-nav";
import SEO from "../components/seo";
import Intro from "../components/intro";
import scrollHandler from "../components/scrollHandler";
import { data, colors } from "../data.js";
import "../scss/global.scss";
import { Link } from "gatsby"
import AloneTogetherLogo from "../images/alone_together_logo.svg"

import Footer from "../components/footer"


const IndexPage = () => {
  const [slide, setSlide] = useState(data[0]);
  const [slideRefs, setSlideRefs] = useState([]);

  useEffect(() => {
    setSlideRefs(elRefs => (
      Array(data.length).fill().map((_, i) => elRefs[i] || React.createRef())
    ));
  }, []);

  const [bg, setBg] = useSpring(() => ({
    backgroundColor: colors["blue"]
  }));

  const [logo, setLogo] = useSpring(() => ({
    transform: `translateY(-200%)`
  }))

  const elPos = element => element ? element.getBoundingClientRect() : {}

  function onScroll() {
    for (var i = slideRefs.length - 1; i >= 0; i--) {
      let current = slideRefs[i].current

      if (
        (elPos(current).top < window.innerHeight) &&
        (elPos(current).bottom > window.innerHeight/2) &&
        (elPos(current).bottom < window.innerHeight*1.5))
      {
        setSlide(data[i])
        setBg({ backgroundColor: colors[data[i].color]})
        setLogo({
          transform: i === 0 ? `translateY(-200%)` : `translateY(0)`
        })
      }
    }
  }

  scrollHandler(onScroll, 200)

  return <Layout>
    <Intro />
    <a.div style={logo} className="header-logo">
      <Link to="/" title="home">
        <AloneTogetherLogo />
      </Link>
    </a.div>

    <a.div style={bg} className={`Slide-top ${slide.color}`} />
    <SEO title="Alone Together" />
    {data.map((block,i) => (
      <article
        id={block.id}
        className={`Slide ${block.color === "white" ? "invert" : ""}`}
        key={block.id}
        ref={slideRefs[i]}>
          <div className={`Slide-content`} style={{
            opacity: slide.id === block.id ? 1 : 0
          }}><Slide current={slide.id === block.id} index={i} {...block} />
          </div>
      </article>
    ))}
    <a.div className="Slide-bg" style={bg} />
    <a.aside
      className={`SlideNav`}
      style={bg}
    >
      <SlideNav active={slide.id} />
    </a.aside>

    <Footer color={slide.color === "yellow" ? "invert" : ""} />
  </Layout>
}

export default IndexPage
