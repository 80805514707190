import React from "react"
import CrisisLinks from "./crisis-links"
import "./footer.scss"

const Footer = ({color}) => (
  <footer className={`footer ${color}`}>
    <CrisisLinks />
  </footer>
)

export default Footer
