import React, { useRef, useState, useEffect, useCallback } from "react"
import { useTransition, a } from "react-spring";
import AloneTogetherLogo from "../images/alone_together_logo.svg"

import "./intro.scss";

const Intro = () => {
  const [complete,setComplete] = useState(false)
  const ref = useRef([])
  const [items, set] = useState([3])

  const reset = useCallback(() => {
    ref.current.map(clearTimeout)
    ref.current = []
    set([3])
    ref.current.push(setTimeout(() => set([1,3,5]), 300))
    ref.current.push(setTimeout(() => set([1,2,3,4,5]), 450))
    ref.current.push(setTimeout(() => set([2,3,4]), 600))
    ref.current.push(setTimeout(() => set([3]), 750))
    ref.current.push(setTimeout(() => set([]), 900))
    ref.current.push(setTimeout(() => setComplete(true), 1050))
  }, [])

  useEffect(() => void reset(), [])

  const t = useTransition(!complete, null, {
    from:  {opacity: 1},
    enter: {opacity: 1},
    leave: {opacity: 0}
  });

  const lineItems = [
    { key: 1, color: "white"},
    { key: 2, color: "white"},
    { key: 3, color: "black"},
    { key: 4, color: "white"},
    { key: 5, color: "white"}
  ]

  return t.map(({item, key, props}) => (item && <a.section
    key={key}
    className={"Intro"}
    style={props}>
      <ul>
        {lineItems.map((item, index) => {
          return <a.li
            key={`${item.key}`}
            style={{
              opacity: items.indexOf(item.key) > -1 ? 1 : 0
            }}
            className={item.color}>
            <AloneTogetherLogo />
          </a.li>
        })}
      </ul>
  </a.section>))
}

export default Intro
