
export const colors = {
  blue: "#4BACEC",
  yellow: "#FCEE21",
  green: "#8CC63F",
  pink: "#D6B2D0",
  white: "#FFFFFF"
}

export const data = [
  {
    heading: null,
    copy: "Coronavirus has turned the world upside down- from being stuck inside to caring for sick family members to needing to keep 6 feet between yourself and... everyone. You are not alone, there are millions of people navigating the new normal and most of them are wearing yesterday’s sweatpants.",
    link: null,
    linkCopy: null,
    cta: `<p style="font-weight: 700; margin: 0;"></p>
      <p style="font-weight: 700; margin-top: 1rem;">Scroll down for ways to take care of yourself and others. You got this.</p>
      <a class="no-deco" href="#stay-home"><h3>&darr;</h3></a>`,
    id: "home",
    color: "blue"
  },
  {
    heading: "Stay Covered",
    copy: "You may find that your workplace, your favorite restaurants, your entire town is re-opening, but you still have to take precautions to protect yourself and your family. Coronavirus is still active and while different communities will be at different phases, protecting yourself and others can help maintain lower levels of infection.",
    cta: `
    <p class="Slide-subhead">
      <a href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">There are plenty of ways to keep healthy and safe</a> but staying 6 feet away from others, wearing a mask, and washing your hands are the best ways to stop the spread. We are in this together!
    </p>
    <ul class="max-width">
      <li>We ask that you mask! <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">Wear a mask</a> in public settings (for those over the age of 2) so  you can help slow the spread of the virus and protect yourself and others</li>
      <li>If you are planning to go out in public: Follow your state and local guidelines for guidance on gatherings, recreation, and work</li>
    </ul>`,
    id: "stay-home",
    color: "yellow",
    icon: "home"
  },
  {
    heading: "Stay Safe This Holiday",
    copy: "This holiday season, consider how you can modify your plans to reduce the spread of COVID-19 and keep your friends, family, and community safe!",
    cta: `
    <ul class="no-max-width">
      <li>
        Planning on holiday shopping? Don’t forget to always <a href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">wear a mask</a>, especially when indoors.
      </li>
      <li>
        <a href='https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-risk.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-risk.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">Consider celebrating virtually</a> this year and host a large gathering online instead! 
      </li>
      <li>
        Avoid the hustle and bustle of holiday traffic and <a href='https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-during-covid19.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-during-covid19.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">rethink traveling</a> this year. Staying home is the best way to protect yourself and others.
      </li>
      <li>
        If you’re planning on <a href='https://www.cdc.gov/coronavirus/2019-ncov/community/large-events/considerations-for-events-gatherings.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/community/large-events/considerations-for-events-gatherings.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">hosting a gathering</a>, keep your invite list at a minimum. The larger the gathering, the higher the risk.
      </li>
    </ul>
    <p>While staying at home is the best way of stopping the spread, if you are traveling, keep these tips in mind:</p>
    <ul>
      <li>
        <a href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/social-distancing.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/social-distancing.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">Keep at least a 6-foot distance</a> from others, be sure you <a href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html' onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">pack plenty of masks</a> when in public settings (this includes airports, planes, rest-stops, etc.), and remember to <a href='https://www.cdc.gov/handwashing/when-how-handwashing.html' onclick="captureOutboundLink('https://www.cdc.gov/handwashing/when-how-handwashing.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC site">wash your hands often</a> and avoid touching your face. 
      </li>
    </ul>
    `,
    id: "holidays",
    color: "blue",
    link: null,
    linkCopy: null,
    icon: "snowflake"
  },
  {
    heading: "#BackToSchoolTogether",
    copy: `This school year is going to look different for everyone. Many parents and students will make difficult decisions about school re-entry. Whether you learn from home, go back into the classroom or a hybrid, you can take precautions to stay healthy. Do it for your classmates and together we can cancel Covid, not classes.`,
    link: "https://seizetheawkward.org",
    linkCopy: "",
    cta: `<ul class="max-width">
      <li>Stick to the basics to <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/index.html" onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/index.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC">stop the spread</a>. Wash your hands often, wear a mask, practice social distancing (don’t share books and supplies), stay home if you are sick and avoid large gatherings.</li>
      <li>Do you! We all have to make important choices about how best to keep ourselves and our families healthy. Consider your health and needs, <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/decision-tool.html" onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/decision-tool.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC">assess your options</a>, don't be afraid to ask <a href="https://risefirst.org/covid-19-resources" onclick="captureOutboundLink('https://risefirst.org/covid-19-resources'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC">for help or resources</a>, reach out to your dean or school administrator and <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/parent-checklist.html" onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/parent-checklist.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC">make a plan</a>.</li>
      <li>On campus? Assume the virus is all around you. Disinfect frequently-touched surfaces like your phone. Try to avoid confined spaces like parties, but if you do go wear a mask. Maintain distance with your roommate if possible and encourage them to practice safe and healthy behaviors.</li>
      <li>Be kind to yourself and others, this is a <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/stress-coping/index.html" onclick="captureOutboundLink('https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/stress-coping/index.html'); return false;" target="_blank" rel="noopener noreferrer" title="go to CDC">stressful and demanding</a> time and your mental health is important.</li>
    </ul>`,
    id: "stay-connected",
    color: "green",
    icon: "connected"
  },
  // {
  //   heading: "Stay Connected",
  //   copy: `Call your mom, she loves hearing from you. Keeping connected with your family, friends, and loved ones will help add regularity and routine to your day. Just because we’re physically apart doesn’t mean we can’t be connected! Find creative ways to stay in touch with your peeps. Whether it’s starting an online game night, tuning in to a livestream concert, or hosting a viewing party of your favorite show- digital connections can keep the "social" in social distancing!`,
  //   link: "https://seizetheawkward.org",
  //   linkCopy: "",
  //   cta: `<p style="font-weight: 700">
  //     For more ways to stay connected, check out <a href="https://seizetheawkward.org" onclick="captureOutboundLink('https://seizetheawkward.org'); return false;" target="_blank" rel="noopener noreferrer" title="go to seize the awkward site">Seize The Awkward</a>.
  //     </p>`,
  //   id: "stay-connected",
  //   color: "green",
  //   icon: "connected"
  // },
  {
    heading: "Stay Calm",
    copy: `Hold up, wait a minute. Take a moment for you. It’s really easy to forget to check in with ourselves but it’s a crucial time to nurture your <a href="https://mhanational.org/covid19" onclick="captureOutboundLink('https://mhanational.org/covid19'); return false;" target="_blank" rel="noopener noreferrer" title="go to mhanational site">mental wellness</a>. There’s no one size fits all solution to addressing feelings of stress, anxiety or sadness, but it’s important to find ways to cope.`,
    link: "https://www.headspace.com/covid-19",
    linkCopy: "headspace",
    cta: `<p class="Slide-subhead">Whether you’ve been personally affected by these events or you’re feeling overwhelmed from news and social media- your feelings are valid.  We know it’s tough out there- but we got you.</p>
    <p style="font-weight: 700">
      Find coping tools, support, and more at <a href="https://www.vibrant.org/safespace/" onclick="captureOutboundLink('https://www.vibrant.org/safespace/'); return false;" target="_blank" rel="noopener noreferrer" title="go to vibrant site">Vibrant’s safe space</a>. It’s digital, anonymous, and free.
      </p>`,
    id: "stay-calm",
    color: "pink",
    icon: "calm"
  },
  // {
  //   heading: "Stay Active",
  //   copy: "Work it out. Physical activity can help you clear your mind and get rid of all that extra stress. A simple stretch in the morning, a jog outside, or a dance party on video chat with friends can improve your emotional and physical health.",
  //   link: "https://www.youtube.com/playlist?list=PLbpi6ZahtOH4OqtS9rvYW_jf-agPEMEsP",
  //   linkCopy: "free workout videos",
  //   cta: `<p style="font-weight: 700">
  //     Try these <a href="https://www.youtube.com/playlist?list=PLbpi6ZahtOH4OqtS9rvYW_jf-agPEMEsP" onclick="captureOutboundLink('https://www.youtube.com/playlist?list=PLbpi6ZahtOH4OqtS9rvYW_jf-agPEMEsP'); return false;" target="_blank" rel="noopener noreferrer" title="Go to youtube for videos">free workout videos</a> to turn your home into a personal gym! (and be sure to consult with your health care provider before beginning any exercise program)
  //     </p>`,
  //   id: "stay-active",
  //   color: "blue",
  //   icon: "active"
  // },
  {
    heading: "More Tips for Coping",
    copy: "Remember, seeking help is a strength, not a weakness.",
    link: null,
    linkCopy: null,
    cta: `
      <ul>
        <li>
          <span>Do you need to speak to someone now? </span>
          <a href="https://www.samhsa.gov/find-help/disaster-distress-helpline" onclick="captureOutboundLink('https://www.samhsa.gov/find-help/disaster-distress-helpline'); return false;" target="_blank" rel="noopener noreferrer" title="go to samhsa site">SAMHSA: Disaster Distress Helpline</a>
        </li>
        <li>
          <span>Are you feeling stressed? </span>
          <a href="https://www.virusanxiety.com/" onclick="captureOutboundLink('https://www.virusanxiety.com/'); return false;" target="_blank" rel="noopener noreferrer" title="go to virusanxiety site">Shine: Care for Your Coronavirus Anxiety</a>
        </li>
        <li>
          <span>Are you facing grief?</span>
          <a href="https://www.psychologytoday.com/us/blog/bravery-in-bereavement/202004/how-cope-bereavement-during-the-covid-19-pandemic" onclick="captureOutboundLink('https://www.psychologytoday.com/us/blog/bravery-in-bereavement/202004/how-cope-bereavement-during-the-covid-19-pandemic'); return false;" target="_blank" rel="noopener noreferrer" title="go to psychology today article">Psychology Today: How to Cope with Bereavement During the COVID-19 Pandemic</a>
          <a href="https://psychcentral.com/blog/grief-loss-in-the-time-of-coronavirus/" onclick="captureOutboundLink('https://psychcentral.com/blog/grief-loss-in-the-time-of-coronavirus/'); return false;" target="_blank" rel="noopener noreferrer" title="go to psychcentral article">Psych Central: Grief & Loss in the Time of Coronavirus</a>
        </li>
        <li>
          <span>Are you a parent?</span>
          <a href="https://childmind.org/coping-during-covid-19-resources-for-parents/" onclick="captureOutboundLink('https://childmind.org/coping-during-covid-19-resources-for-parents/'); return false;" target="_blank" rel="noopener noreferrer" title="">Child Mind: Supporting Families During COVID-19</a>
          <a href="https://www.pbs.org/parents/thrive/how-to-talk-to-your-kids-about-coronavirus" onclick="captureOutboundLink('https://www.pbs.org/parents/thrive/how-to-talk-to-your-kids-about-coronavirus'); return false;" target="_blank" rel="noopener noreferrer" title="">PBS: How to Talk to Your Kids About Coronavirus</a>
          <a href="https://www.sesamestreet.org/caring" onclick="captureOutboundLink('https://www.sesamestreet.org/caring'); return false;" target="_blank" rel="noopener noreferrer" title="">Sesame Street: Caring for Each Other</a>
        </li>
        <li>
          <span>Are you a student?</span>
          <a href="https://www.activeminds.org/blog/coping-and-staying-emotionally-well-during-covid-19-related-school-closures/" onclick="captureOutboundLink('https://www.activeminds.org/blog/coping-and-staying-emotionally-well-during-covid-19-related-school-closures/'); return false;" target="_blank" rel="noopener noreferrer" title="">Active Minds: Coping and Staying Emotionally Well During COVID-19-related School closures</a>
        </li>
        <li>
          <span>Are you part of the LGBTQ community?</span>
          <a href="https://www.thetrevorproject.org/" onclick="captureOutboundLink('https://www.thetrevorproject.org/'); return false;" target="_blank" rel="noopener noreferrer" title="">The Trevor Project — Saving Young LGBTQ Lives</a>
        </li>
      </ul>
      <p>For up-to-date info on the coronavirus, visit coronavirus.gov</p>
      `,
    id: "more-resources",
    color: "yellow",
    icon: "resources"
  }
];
